const usePWAStorage = () => {

    const DB_NAME = "PWAStorage";
    const STORE_NAME = "lastUrlStore";

// פתיחת חיבור למסד הנתונים
const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    };

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject("Failed to open DB");
  });
};

// שמירת ה-URL האחרון
 const saveLastUrl = async (url) => {
 console.log("url", url);
  const db = await openDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  const store = tx.objectStore(STORE_NAME);
  store.put(url, "lastUrl");
};

// שליפת ה-URL האחרון
 const getLastUrl = async () => {
  const db = await openDB();
  return new Promise((resolve) => {
    const tx = db.transaction(STORE_NAME, "readonly");
    const store = tx.objectStore(STORE_NAME);
    const request = store.get("lastUrl");

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => resolve(null);
  });
};


return{getLastUrl, saveLastUrl}
}
export default  usePWAStorage