import { Stack } from "@mui/material";
import Header from "./Header";
import Description from "./Description";
import CustomButton from "../../components/CustomButton";
import Footer from "../../components/Footer";
import ServicesCall from "../components/servicesCall";
import { useSelector } from "react-redux";
import NullCheck from "../../components/NullCheck";
import usePwa from "../../hooks/usePwa";
import MiniSiteList from "../components/MiniSiteList";
import ContactUs from "../components/ContactUs";
import { MINI_SITE_TYPE_ID } from "../../config/const";
import BackButton from "../components/BackButton";

const ClassicTemp = () => {
  const { installApp, isInPwa } = usePwa()
  const data = useSelector((state) => state?.biz?.data);
  const pwaNotInstall = useSelector((state) => state?.pwa?.pwaNotInstall);

  return (
    <Stack position='relative' height="100%">
      <Stack
        spacing={0.5}
        sx={{
          overflowX: "hidden",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
        height="100%"
      >
        <Header />

        <NullCheck valueForCheck={!isInPwa() && pwaNotInstall && data?.miniSite?.hasApp}>
          <CustomButton title='INSTALL_APP' onClick={installApp}/>
        </NullCheck>

        <Description />

        <NullCheck valueForCheck={data?.miniSite?.type === MINI_SITE_TYPE_ID.PORTAL}>
          <MiniSiteList/>  
        </NullCheck>

        <ServicesCall />

        <ContactUs/>          
        <Footer />
      </Stack>
    </Stack>
  );
};
export default ClassicTemp;
