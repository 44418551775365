import israeFlag from "../assets/Flags/Israe_flag.jpg";
import usFlag from "../assets/Flags/united_states_flag.jpg";

export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "MM:HH";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const LANGUAGES_LIST = [
  { id: 1, i18Key: "LANG", code: "he", dir: "rtl", img: israeFlag },
  { id: 2, i18Key: "LANG", code: "en", dir: "ltr", img: usFlag },
];

export const LANGUAGES = {
  he: { id: 1, i18Key: "LANG", code: "he", dir: "rtl", img: israeFlag },
  en: { id: 2, i18Key: "LANG", code: "en", dir: "ltr", img: usFlag },
};

export const TEMP_TYPE_ID = {
  BASIC: "BASIC",
  CLASSIC: "CLASSIC",
  BUBBLE: "BUBBLE",
  ORDER_TAXI: "ORDER_TAXI",
  CLASSIC_1: "CLASSIC_1",
  DESIGN_1: "DESIGN_1",
};

export const MINI_SITE_TYPE_ID =  {
  PORTAL: 1,
  DEPARTMENT: 2,
  CLIENT: 3,
  WITHOUT_CLIENT: 4,
}

export const TEMP_TYPES = [
  {
    id: TEMP_TYPE_ID?.BASIC,
    name: TEMP_TYPE_ID?.BASIC,
    show: true,
  },
  {
    id: TEMP_TYPE_ID?.BUBBLE,
    name: TEMP_TYPE_ID?.BUBBLE,
    show: true,
  },
  {
    id: TEMP_TYPE_ID?.CLASSIC,
    name: TEMP_TYPE_ID?.CLASSIC,
    show: true,
  },
  {
    id: TEMP_TYPE_ID?.CLASSIC_1,
    name: TEMP_TYPE_ID?.CLASSIC_1,
    show: true,
  },
  {
    id: TEMP_TYPE_ID?.DESIGN_1,
    name: TEMP_TYPE_ID?.DESIGN_1,
    show: true,
  },
];

export const PLANS_TYPE_ID = {
  SMART_QR: 1,
  SERVICES_CALL: 2,
  CONTACT_US: 3,
  SCHEDULE: 4,
};

export const PMT_TYPE_ID = {
  PAY: 1,
  NOT_PAY: 2,
  SERVICE: 3,
};

export const PLUGIN_NAME = {
  CALENDAR: "CALENDAR",
  BUSINESS_CARD: "BUSINESS_CARD",
  SERVICES_CALL: "SERVICES_CALL",
  APPOINTMENTS: "APPOINTMENTS",
  SMART_QR: "SMART_QR",
  CONTACT_US: "CONTACT_US",
  ATTENDANCE: "ATTENDANCE",
  RECOMMEND: "RECOMMEND",
  VIDEO_CAROUSEL: "VIDEO_CAROUSEL",
};
