import { Email, FmdGood, LocalPhone } from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CustomIconButton from "../../components/CustomIconButton";
import { useTranslation } from "react-i18next";
import NullCheck from "../../components/NullCheck";
import CustomDialog from "../../components/CustomDialog";
import { useState } from "react";
import CustomButton from "../../components/CustomButton";

const ContactUs = () => {
    const { t } = useTranslation();
    const data = useSelector((state) => state?.biz?.data);
    const [selectAddrPlatfom, setSelectAddrPlatfom] = useState({open: false, addr: null})

    const openGoogleAddr = (addr) => {
        window.open(`https://www.google.com/maps?q=${addr?.replace(" ", "+")}`, '_blank');
        setSelectAddrPlatfom({open: false, addr: null})
    }

    const openWazeAddr = (addr) => {
        window.open(`https://waze.com/ul?q=${addr?.replace(" ", "+")}`, '_blank');
        setSelectAddrPlatfom({open: false, addr: null})
    }

    return (
        <Stack padding={1} spacing={2} bgcolor="primary.main">
            <Stack>
                <Typography variant="h6" fontWeight="bold" color="secondary">
                    {t('CONTACT_US')}
                </Typography>
                <Divider sx={{ bgcolor: "secondary.light" }} />
            </Stack>

            <Stack direction='row' gap={2}>
                <NullCheck valueForCheck={data?.miniSite?.tel}>
                    <Stack direction='row' spacing={0.5}>
                        <CustomIconButton
                            href={`tel:${data?.miniSite?.tel}`}
                            bgcolor='secondary.main'
                            iconColor='primary'
                            icon={<LocalPhone fontSize='large'/>}
                        />
                    </Stack>
                </NullCheck>

                <NullCheck valueForCheck={data?.miniSite?.email}>
                    <Stack direction='row' spacing={0.5}>
                        <CustomIconButton
                            href={`mailto:${data?.miniSite?.email}`}
                            bgcolor='secondary.main'
                            iconColor='primary'
                            icon={<Email fontSize='large'/>}
                        />
                    </Stack>
                </NullCheck>

                <NullCheck valueForCheck={data?.miniSite?.addr}>
                    <Stack direction='row' spacing={0.5}>
                        <CustomIconButton
                            onClick={() => setSelectAddrPlatfom({open: true, addr: data?.miniSite?.addr})}
                            bgcolor='secondary.main'
                            iconColor='primary'
                            icon={<FmdGood fontSize='large'/>}
                        />
                    </Stack>
                </NullCheck>
            </Stack>

            <CustomDialog 
                open={selectAddrPlatfom?.open}
                onClose={() => setSelectAddrPlatfom({open: false, addr: null})}
                title='OPEN_USING'
            >
                <Stack spacing={2}>
                    <CustomButton 
                        title='Google Map' 
                        onClick={() => openGoogleAddr(selectAddrPlatfom?.addr)}
                    />
                    <CustomButton 
                        title='waze' 
                        onClick={() => openWazeAddr(selectAddrPlatfom?.addr)}
                    />
                </Stack>
            </CustomDialog>
        </Stack>
    );
};

export default ContactUs;