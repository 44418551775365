import { Card, Grid, Icon, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MiniSiteList = () => {
    const navigate = useNavigate();
    const data = useSelector((state) => state?.biz?.data);

    if(data?.miniSiteList?.length === 0){
    <Stack>
        <Typography>אין מידע להצגה</Typography>
    </Stack>
    }

    const onNavigate = (site) => {
        navigate(`/${data?.biz?.path}/${site?.path}`)
    }
    
    return (
    <Stack padding={1} spacing={2} bgcolor="primary.main">
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            {data?.miniSiteList?.map((site, idx) => {
                return(
                    <Grid item xs={2} sm={4} md={4} key={idx}>
                        <Card sx={{height: 100}}>
                            <Stack 
                                height='100%' 
                                justifyContent="center" 
                                alignItems='center'
                                bgcolor='secondary.main'
                                onClick={() => onNavigate(site)}
                            >
                                <Icon sx={{fontSize: 40, color: "primary.main"}}>
                                    {site?.icon}
                                </Icon>
                                <Typography  
                                    color='primary'
                                    fontSize='bold'
                                    noWrap
                                    sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        maxWidth: 120,
                                    }}
                                >
                                    {`${site?.title}`}
                                </Typography>
                            </Stack >
                        </Card>
                    </Grid>
                )
            })}
        </Grid>
    </Stack>
    );
};

export default MiniSiteList;