import { configureStore } from "@reduxjs/toolkit";
import userSettingsSlice from "./userSettingsSlice";
import bizSlice from "./cardSlice";
import sysSlice from "./sysSlice";
import pwaSlice from "./pwaSlice";
import smartQrSlice from "./smartQrSlice";

const rootReducer = configureStore({
  reducer: {
    userSettings: userSettingsSlice,
    sys: sysSlice,
    biz: bizSlice,
    pwa: pwaSlice,
    smartQr: smartQrSlice,
  },
});

export default rootReducer;
