const he = {
  /** Global */
  ROLE: "תפקיד",
  DESCRIPTION: "תיאור",
  WHO_WE_ARE: "מי אנחנו ?",
  WAZE: "Waze",
  GOOGLE_MAP: "Google Map",
  SAVE_CSV: "שמור איש קשר",
  ACCEPT: "אישור",
  ERROR: "שגיאה",
  DOWNLOAD: "הורד",
  PRINT: "הדפס",
  MINUTES: "דקות",
  EXAMPLE_TEMP: "בחר עיצוב",
  NEXT:"המשך",
  SALES:"מכירות",
  SERVICES:"תמיכה טכנית",
  SOON1:"המערכת שלנו כבר חוסכת באנרגיה",
  SOON2:"האתר החדש בדרך",
  FORGIVENESS:"עמכם הסליחה",
  LOGIN_CLIENT:"כניסה למערכת",
  INSTALL_APP:"הורד אפליקציה",
  MINI_SITE_NOT_ACTIVE:"אתר לא פעיל",
  OPEN_USING:"פתח באמצעות",

  /** Templates */
  BASIC: "בסיסי",
  CLASSIC: "קלאסי",
  WAVES: "גלים",
  BUBBLE: "בועות",
  TEMP_TYPE_ID: "בחר עיצוב",
  CLASSIC_1: "קלאסי 1",
  DESIGN_1: "עיצוב 1",

  /** Validations */
  REQUIRED: "שדה חובה",
  URL_ERROR: "נא להזין כתובת תקינה, לדוגמה :https://www.google.co.il",
  CEL_ISRAELI_ERROR: "נא להזין מספר פלאפון תקין",
  EMAIL_ERROR: 'נא להזין דוא"ל תקין',
  PATH_ERROR:
    "נא להזין אותיות באנגלית ומספרים (מינימום 3 אותיות מקסימום 20 אותיות)",
  PASSWORD_ERROR:
    "מינימום שמונה תווים, לפחות אות אחת גדולה, אות קטנה אחת, ספרה אחת ותו מיוחד אחד",

  /** Contact Us */
  CONTACT_US: "צור קשר",
  SEMD_MASSGE: "שלח הודעה",
  CONTENT: "תוכן",
  NAME: "שם מלא",
  TEL: "טלפון",
  EMAIL: 'דוא"ל',
  TITLE: "כותרת",
  SEND: "שלח",
  SEND_SUCCEEDED: "הודעה נשלחה בהצלחה",

  /** Smart Qr */
  PASSWORD:"סיסמה",
  PASSWORD_INFO:"כדי להמשיך יש להזין סיסמה",
  PASSWORD_ERROR:"סיסמה לא תקינה",

  /** Services Calls */
  SERVICES_CALLS: "פתיחת קריאת שירות",
  CLIENT_ID: "מספר לקוח",
  ADDR: "מיקום",
  CHECK_CLIENT: "חפש לקוח",
  CLIENT_CALL_NUMNER: "מספר קריאה",
  ADD_ITEM: "הוסף",
  ADD_ITEMS: "הוסף מוצרים",
  DESC_PROBLEM: "תיאור התקלה",
  SEND_SERVICES_SUCCEEDED: "קריאה נשלחה בהצלחה",
  ADD_FAULT:"הוסף תקלה",
  ADD_FAULTS: "הוסף תקלות",
  DEPARTMENT_ID:"מזהה מחלקה",

  /** Services Summary */
  SERVICES_SUMMARY: "סיכום ביקור",
  SERVICES_CALL_AND_ORDER: "קריאה/הזמנה מספר",
  SERVICES_CALL: "מספר קריאה",
  PAY: "לחויב",
  NOT_PAY: "ללא חיוב",
  SERVICE: "שרות",

  /** Recommend */
  RECOMMEND: "המלצות",

  //** */
  DRIVER_ON_WAY: "איזה כיף המונית בדרך!",
  NEW_ORDER: "הזמנה חדשה",
  DESTINATION_ADDR: "יעד",
  COLLECTION_ADDR: "נק' איסוף",
  CLIENT_TEL: "טלפון",
  CLIENT_NAME: "שם",
  PLEASE_NOT_CLOSE_PAGE: "בבקשה לא לסגור דף זה עד קבלת תשובה",
  CANCELLATION_ORDER: "ביטול הזמנה",
  TAXI_ORDER: "הזמנת מונית",
  LOOKING_TAXI: "מחפש מונית",
  TAXI_NUMBER: "מספר מונית",
  APPROXIMATE_TIME: "זמן הגעה משוער",
  CANCEL_ORDER: "לצערנו לא מצאנו מונית",
  SAME_ADDRESSES_ERROR: "לא ניתן להזמין מונית עם שני כתובות זהות",
};

export default he;
