import { Divider, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import LOGO from '../assets/logo.png'

const NotActive = () => {
    const { t } = useTranslation();
    
    return(
        <Stack 
            height="98%" 
            width='100%' 
            justifyContent='space-between' 
            alignItems='center'
        >
            <img src={LOGO} style={{width: 250}}/>

            <Typography variant="h3">
                {t("MINI_SITE_NOT_ACTIVE")}
            </Typography>

            <Stack alignItems='center'>
                <Typography variant="h6">{`${t('SERVICES')}`}</Typography>
                <Divider/>
                <Typography>{`${t('TEL')}: 055-7773229`}</Typography>
                <Typography>{`${t('EMAIL')}: help.mybee@gmail.com`}</Typography>
            </Stack>
        </Stack>
    )
}

export default NotActive