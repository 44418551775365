import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DEFAULT_USER from "../../assets/default_user.jpg";
const Header = () => {
  const theme = useTheme();
  const data = useSelector((state) => state?.biz?.data);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if(data?.biz?.imageLogo) setImage(data?.biz?.imageLogo);
    else setImage(DEFAULT_USER);
  }, [data]);

  return (
    <Stack
      bgcolor="primary.main"
      minHeight={220}
      maxHeight={300}
      position="relative"
    >
      <Stack boxShadow={`0 4px 2px -2px`}>
        <img
          style={{ width: "100%", height: 145, objectFit: "cover" }}
          src={data?.biz?.imageHeader}
        />
      </Stack>

      <Stack
        direction="row"
        gap={1}
        alignItems="end"
        position="absolute"
        top={90}
        right={15}
      >
        <img
          style={{
            width: 110,
            height: 110,
            objectFit: "cover",
            borderRadius: 15,
            border: `3px solid ${theme.palette.primary.main}`,
          }}
          src={image}
        />
        <Stack>
          <Typography variant="h5" color="secondary">
            {data?.miniSite?.title ?? ''}
          </Typography>

          <Typography fontWeight="bold" color="secondary">
            {data?.miniSite?.subTitle ?? ''}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default Header;
