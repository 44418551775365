import { Fade, Stack } from "@mui/material";
import Loading from "../components/Loading";
import { useSelector } from "react-redux";
import { LANGUAGES_LIST } from "../config/const";
import { useEffect } from "react";
import usePwa from "../hooks/usePwa";
import { useLocation, useNavigate } from "react-router-dom";
import usePWAStorage from "../hooks/useIndexedDB";

const Layout = ({ children }) => {
  const { isInPwa } = usePwa()
  const { saveLastUrl, getLastUrl } = usePWAStorage()
  const location = useLocation();
  const navigate = useNavigate();
  const langType = useSelector((state) => state?.userSettings?.selectedLang);
  const pwaNotInstall = useSelector((state) => state?.pwa?.pwaNotInstall);
  document.dir = LANGUAGES_LIST.find((lang) => lang.id === langType)?.dir;

  useEffect(() => {
    if (isInPwa()) {
      getLastUrl().then((lastUrl) => {
        if (lastUrl) navigate(lastUrl);
      });
    }
  }, [navigate]);

  useEffect(() => {
    if (!isInPwa() && pwaNotInstall) {
      saveLastUrl(location.pathname);
    }
  }, [location.pathname, pwaNotInstall]);

  return (
    <Stack alignItems="center" height={{ xs: "100svh", md: "100vh" }}>
      <Fade in={true}>
        <Stack
          boxShadow={3}
          width="100vw"
          height="100%"
          maxWidth={500}
          bgcolor="bg.main"
        >
          {children}
          <Loading />
        </Stack>
      </Fade>
    </Stack>
  );
};
export default Layout;
