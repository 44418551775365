import { Divider, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/CustomButton";
import { useSelector } from "react-redux";
import axios from "axios";
import { apiUrl } from "../../../config/settings";
import Lottie from "lottie-react";
import loadingButton from "../../../assets/loadingButton.json";
import { useEffect, useState } from "react";
import { MINI_SITE_TYPE_ID, PLUGIN_NAME } from "../../../config/const";
import ClientItems from "./ClientItems";
import CheckClient from "./CheckClient";
import ClientDetails from "./ClientDetails";
import { useParams } from "react-router-dom";
import NullCheck from "../../../components/NullCheck";

const ServicesCall = ({ bgColor }) => {
  const { t } = useTranslation();
  const { clientGuid } = useParams();
  const plugins = useSelector((state) => state?.biz?.data?.plugins);
  const data = useSelector((state) => state?.biz?.data);
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [itemList, setItemList] = useState([]);
  const {handleSubmit,control,reset,watch,formState: { errors },} = useForm();
  const clientId = watch("clientId");

console.log("data", data);
  useEffect(() => {
    if(data?.client?.id){
      const client = {...data?.client, name: data?.client?.contactName, clientId: data?.client.guid}
      setClient(client);
    }
    else if (clientGuid && data?.biz?.id) {
      getClient(clientGuid);
    }
  }, [clientGuid, data]);

  const getClient = (clientGuid) => {
    if (!clientId && !clientGuid) return;
    const id = clientId || clientGuid;

    axios
      .get(`${apiUrl}/miniSite/checkClient/${data?.biz?.id}/${id}`)
      .then((res) => {
        const value = res?.data?.data;
        const client = {...value, name: value?.contactName, clientId: value.guid}
        setClient(client);
      })
      .catch((error) => {
        alert("לקוח לא נמצא");
        console.log("error", error);
      });
  };

  const setClient = (client) => {
    reset(client);
    setClientData(client);
  }

  const onSubmit = (values) => {
    if (itemList.length === 0) return alert("יש להוסיף לפחות מוצר אחד לרשימה");

    setLoading(true);
    const obj = {
      isRead: true,
      employeeId: data?.emp?.id,
      department: data?.emp?.department,
      clienCompany: clientData?.company,
      clientId: values.id ?? "",
      contentWithItems: itemList,
      ...values,
    };

    axios
      .post(`${apiUrl}/servicesCall`, obj)
      .then((res) => {
        setSucceeded(true);
        setClientData(null);
        reset({name: "",clientId: "",tel: "",addr: "",content: "",employeeId: ""});

        const timer = setTimeout(() => {
          setLoading(false);
          setSucceeded(false);
          setItemList([]);
          if (clientGuid) getClient(clientGuid);
          if (data?.client.guid) getClient(clientGuid);
          if(data?.client.guid) getClient(data?.client.guid);
          clearTimeout(timer);
        }, 3000);
      })
      .catch((error) => {
        alert("אירוע שגיאה, נסה שנית");
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <Stack
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          width={250}
          height={250}
          justifyContent="center"
          alignItems="center"
        >
          <Lottie animationData={loadingButton} loop={true} />
          {succeeded ? (
            <Typography fontWeight="bold">
              {t("SEND_SERVICES_SUCCEEDED")}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
    );
  }

  return (
    <NullCheck valueForCheck={data?.miniSite?.type !== MINI_SITE_TYPE_ID.PORTAL}>
      <Stack padding={1} bgcolor={bgColor ?? "secondary.main"}>
        <Stack border={`2px solid black`}>
          <Stack padding={1}>
            <Typography variant="h6" fontWeight="bold" color="primary">
              {t("SERVICES_CALLS")}
            </Typography>
            <Divider sx={{ bgcolor: "secondary.light" }} />
          </Stack>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1}>
              <Stack padding={1} gap={2} justifyContent="center">
                <CheckClient
                  clientData={clientData}
                  control={control}
                  errors={errors}
                  getClient={getClient}
                />

                {clientData ? (
                  <Stack>
                    <ClientDetails control={control} errors={errors} />
                    <ClientItems
                      itemList={itemList}
                      setItemList={setItemList}
                      clientData={clientData}
                    />
                  </Stack>
                ) : null}
              </Stack>

              {clientData ? (
                <Stack padding={1}>
                  <CustomButton loading title="SEND" submit />
                </Stack>
              ) : null}
            </Stack>
          </form>
        </Stack>
      </Stack>
    </NullCheck>
  );
};
export default ServicesCall;
