import { createSlice } from "@reduxjs/toolkit";

const pwaSlice = createSlice({
  name: "pwa",
  initialState: {
    deferredPrompt: null,
    pwaNotInstall: false
  },
  reducers: {
    setDeferredPrompt(state, action) {state.deferredPrompt = action.payload},
    setPwaNotInstall(state, action) {state.pwaNotInstall = action.payload},
  },
});

export const { setDeferredPrompt, setPwaNotInstall } = pwaSlice.actions;
export default pwaSlice.reducer;
