import { Stack } from "@mui/material";
import TextInput from "../../../components/files/TextInput";
import CustomButton from "../../../components/CustomButton";

const CheckClient = ({ errors, control, clientData, getClient }) => {
  return (
    <Stack spacing={2}>
      <TextInput
        disabled={clientData ? true : false}
        width={{ xs: "100%", md: "auto" }}
        errors={errors}
        name="clientId"
        title="DEPARTMENT_ID"
        // title="CLIENT_ID"
        control={control}
      />

      {!clientData ? (
        <CustomButton title="CHECK_CLIENT" onClick={getClient} />
      ) : null}
    </Stack>
  );
};
export default CheckClient;
