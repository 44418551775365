import { useSelector } from "react-redux";
import ClassicTemp from "../templates/classic";
import { Stack } from "@mui/material";
import NullCheck from "../components/NullCheck";
import ChangeTemp from "../components/ChangeTemp";
import NotActive from "../components/NotActive";

const TemplatesRoutes = () => {
  const data = useSelector((state) => state?.biz?.data);

  if(data?.id === -100) {
    return (
      <NotActive/>
    )
  }
  return (
    <Stack>
      <NullCheck valueForCheck={data?.isBizExample}>
        <ChangeTemp />
      </NullCheck>
      
        <ClassicTemp />
    </Stack>
  );
};
export default TemplatesRoutes;
