import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setShowLoading } from "../store/sysSlice";
import { useDispatch } from "react-redux";
import { Divider, Stack, Typography } from "@mui/material";
import LOGO from '../assets/logo.png'
import ANIMATION from '../assets/Animation.json'
import { useTranslation } from "react-i18next";
import CustomButton from "../components/CustomButton";
import Lottie from "lottie-react";

const ComingSoon = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setShowLoading(false));
    // navigate("/MyBee/services");
  }, []);

  const openBo = () => {
  window.open("https://www.bo.mybee.co.il", "_blank");
  }

  return(
    <Stack 
      height='100%' 
      bgcolor='#f8f9f8'
      alignItems='center' 
      justifyContent='space-between'
      >
      <Stack 
        width='100%' 
        alignItems='center' 
        bgcolor='#fffbd7'
        boxShadow={2}
        borderRadius='0 0 30px 30px'
      >
      <img src={LOGO} style={{width: 250, margin: 2}}/>
      </Stack>
      

      <Stack padding={3} alignItems='center' spacing={1}>
      <Typography textAlign='center' variant="h4">{t('SOON1')}</Typography>
      <Typography textAlign='center' variant="h5">{t('SOON2')}</Typography>
      <Typography textAlign='center' variant="h6">{t('FORGIVENESS')}</Typography>
      <CustomButton title='LOGIN_CLIENT' onClick={openBo}/>
      </Stack>

      <Divider 
        sx={{
          width: "100%",
          "&::before, &::after": { borderColor: "#8c8c8a" }
        }}
      >
      <Stack width={130}><Lottie animationData={ANIMATION} loop/></Stack>
      </Divider>

      <Stack padding={1} pb={5} spacing={2} justifyContent='space-between'>
        <Stack alignItems='center'>
          <Typography variant="h6">{`${t('SALES')}`}</Typography>
          <Divider/>
          <Typography>{`${t('TEL')}: 050-5250604`}</Typography>
          <Typography>{`${t('EMAIL')}: sales.mybee@gmail.com`}</Typography>
        </Stack>

        <Stack alignItems='center'>
          <Typography variant="h6">{`${t('SERVICES')}`}</Typography>
          <Divider/>
          <Typography>{`${t('TEL')}: 055-7773229`}</Typography>
          <Typography>{`${t('EMAIL')}: help.mybee@gmail.com`}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default ComingSoon;
