import { Stack, Typography, useTheme } from "@mui/material";
import generatePDF from "react-to-pdf";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CustomButton from "../components/CustomButton";
import { useEffect, useRef, useState } from "react";
import { apiUrl } from "../config/settings";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoading } from "../store/sysSlice";
import { setBizData } from "../store/cardSlice";
import NullCheck from "../components/NullCheck";
import { DATE_TIME_FORMAT, PMT_TYPE_ID } from "../config/const";
import { findKeyByValue, lineText, listTolineText } from "../config/functions";
import { useReactToPrint } from "react-to-print";

const ServicesSummary = () => {
  const summaryRef = useRef(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { bizPath, guid } = useParams();
  const { t } = useTranslation();
  const biz = useSelector((state) => state?.biz?.data);
  const [data, setData] = useState(null);

  const clientDetailsStyle = {
    textAlign: "center",
  };

  const title = {
    margin: 0,
    fontSize: 14,
    fontWeight: "bold",
  };
  const value = {
    margin: 0,
  };
  const pStyle = {
    margin: 0,
  };

  const getTargetElement = () => document.getElementById("summary");

  const options = {
    filename: "Services-Summary.pdf",
    page: {
      margin: 4,
      //   orientation: "landscape",
    },
  };

  useEffect(() => {
    if (bizPath && guid) {
      getBizData();
      getServices();
    }
  }, [bizPath, guid]);

  const getBizData = () => {
    if (bizPath) {
      dispatch(setShowLoading(true));
      axios
        .get(`${apiUrl}/miniSite/biz/${bizPath}`)
        .then((response) => {
          if (response.status === 200) {
            dispatch(setBizData(response?.data));
          }
          dispatch(setShowLoading(false));
        })
        .catch((error) => {
          console.error("Auth error");
          dispatch(setShowLoading(false));
        });
    }
  };

  const getServices = () => {
    axios
      .get(`${apiUrl}/servicesCall/servicesSummary/${guid}`)
      .then((res) => {
        dispatch(setShowLoading(false));
        setData(res?.data?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const downloadPdf = () => generatePDF(getTargetElement, options);

  const printFrame = useReactToPrint({
    content: () => summaryRef.current,
  });

  if (!biz || !data) return;

  return (
    <Stack height="100%" spacing={1}>
      <div
        ref={summaryRef}
        id="summary"
        style={{
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
          direction: "rtl",
          width: "100%",
          display: "flex",
          overflowX: "hidden",
          overflowY: "scroll",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <span
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: theme.palette.primary.contrastText,
              }}
            >
              {`${t("SERVICES_SUMMARY")}`}
            </span>
          </div>

          {/* Biz Details */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              padding: 5,
            }}
          >
            <span style={{ fontSize: 16 }}>{`${biz?.bizName}`}</span>
            <span style={{ fontSize: 14 }}>{`${t("TEL")}: ${biz.bizTel}`}</span>
            <NullCheck valueForCheck={biz.bizAddr}>
              <span style={{ fontSize: 14 }}>
                {`${t("ADDR")}: ${biz.bizAddr}`}
              </span>
            </NullCheck>
            <span style={{ fontSize: 14 }}>
              {`${t("EMAIL")}: ${biz.bizEmail}`}
            </span>
          </div>

          {/* contant */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              padding: 5,
            }}
          >
            {/* call id */}
            <span
              style={{
                borderBottom: "2px solid black",
                fontWeight: "bold",
                color: theme.palette.primary.main,
              }}
            >
              {`${t("SERVICES_CALL_AND_ORDER")}: ${data?.callId}`}
            </span>

            {/* client Details */}
            <table border="1" style={{ borderCollapse: "collapse" }}>
              <tr>
                <td style={clientDetailsStyle}>
                  <p style={title}>קריאת לקוח</p>
                  <p style={pStyle}>{data?.clientCallNumner ?? "."}</p>
                </td>
                <td style={clientDetailsStyle}>
                  <p style={title}>חברה</p>
                  <p style={pStyle}>{data.clienCompany ?? "."}</p>
                </td>
                <td style={clientDetailsStyle}>
                  <p style={title}>איש קשר</p>
                  <p style={pStyle}>{data.name ?? "."}</p>
                </td>
              </tr>
            </table>

            {/* call Details */}

            <table border="1" style={{ borderCollapse: "collapse" }}>
              <tr>
                <td style={clientDetailsStyle}>
                  <p style={title}>תאריך עבודה</p>
                  <p style={pStyle}>
                    {data.workDt
                      ? moment(data.workDt).format(DATE_TIME_FORMAT)
                      : "."}
                  </p>
                </td>
                <td style={clientDetailsStyle}>
                  <p style={title}>עובד</p>
                  <p style={pStyle}>{data.employeeName ?? "."}</p>
                </td>
                <td style={clientDetailsStyle}>
                  <p style={title}>סטטוס תשלום</p>
                  <p style={pStyle}>
                    {t(findKeyByValue(PMT_TYPE_ID, data.pmtTypeId)) ?? "."}
                  </p>
                </td>
              </tr>
            </table>

            <div
              style={{
                minHeight: 100,
                padding: 5,
                border: `2px solid ${theme.palette.primary.main}`,
              }}
            >
              {listTolineText(data?.contentWithItems)}
            </div>

            <NullCheck valueForCheck={data?.jobDoneDesc}>
              <div
                className="display-linebreak"
                style={{
                  minHeight: 100,
                  padding: 5,
                  border: `2px solid ${theme.palette.primary.main}`,
                }}
              >
                <p style={{ margin: 0, fontWeight: "bold" }}>בוצע:</p>
                {data?.jobDoneDesc}
              </div>
            </NullCheck>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                gap: 5,
              }}
            >
              <span>{t("סכום לתשלום")}:</span>
              <div
                style={{
                  width: 150,
                  border: `2px solid ${theme.palette.primary.main}`,
                }}
              >
                <p style={{ margin: 0, textAlign: "center", fontSize: 16 }}>
                  {data.pmtTypeId === 1 ? `₪${data.price}` : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Stack
        bgcolor="secondary.main"
        width="100%"
        spacing={1}
        bottom={0}
        zIndex={999}
      >
        <Stack direction="row" justifyContent="space-between" gap={1}>
          <CustomButton fullWidth title="DOWNLOAD" onClick={downloadPdf} />
          <CustomButton fullWidth title="PRINT" onClick={printFrame} />
        </Stack>
        <Typography textAlign="center" fontWeight="bold">
          נוצר ע"י מערכת MyBee
        </Typography>
      </Stack>
    </Stack>
  );
};
export default ServicesSummary;
