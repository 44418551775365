import { createSlice } from "@reduxjs/toolkit";

const bizSlice = createSlice({
  name: "biz",
  initialState: {
    data: null,
  },
  reducers: {
    setBizData(state, action) {state.data = action.payload},
  },
});

export const { setBizData } = bizSlice.actions;
export default bizSlice.reducer;
