import { IconButton } from "@mui/material";

const CustomIconButton = ({
  onClick,
  size,
  autoFocus,
  disabled,
  iconColor,
  bgcolor,
  href,
  icon,
}) => {

  return (
    <IconButton
      href={href}
      sx={{
        "&:hover": {
          bgcolor: bgcolor ?? "primary.main",
        },
        bgcolor: bgcolor ?? "primary.main",
        width: size ?? 50,
        height: size ?? 50,
        borderRadius: 2,
      }}
      target={href ? "_blank" : null}
      color={iconColor ?? "secondary"}
      disabled={disabled ? true : false}
      onClick={onClick ? onClick : () => {}}
      autoFocus={autoFocus ? true : false}
    >
      {icon}
    </IconButton>
  );
};
export default CustomIconButton;
