import { useDispatch, useSelector } from "react-redux";
import { setDeferredPrompt, setPwaNotInstall } from "../store/pwaSlice";

const usePwa = () => {
  const dispatch = useDispatch();
  const deferredPrompt = useSelector((state) => state.pwa?.deferredPrompt);

  const pwaConected = () => {
    const handleBeforeInstallPrompt = (event) => {

      console.log("event", event);
      dispatch(setDeferredPrompt(event));
      event.preventDefault();

      // Show your custom install button
      if (event) {
        dispatch(setPwaNotInstall(true))
        return event;
      }
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Check if the app is already installed
    window.addEventListener("appinstalled", () => {
      console.log('ok')
      // App is already installed, hide the install button
      dispatch(setPwaNotInstall(false))

    });
    return;
  };

  const installApp = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
      console.log("choiceResult.outcome", choiceResult.outcome);
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.error("User dismissed the A2HS prompt");
        }
      });
    }
  };

  const isInPwa = () => {
    return (
      window?.matchMedia("(display-mode: standalone)")?.matches ||
      window?.navigator?.standalone
    );
  };

  return {
    installApp,
    isInPwa,
    pwaConected,
  };
};

export default usePwa;
