import {
  Card,
  Divider,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useTranslation } from "react-i18next";
import { Delete } from "@mui/icons-material";
import NullCheck from "../../../components/NullCheck";

const ClientItems = ({ itemList, setItemList, clientData }) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(clientData?.items[0] ?? "");
  const [hasItem, setHasItem] = useState(true);

  useEffect(() => {
    if(clientData?.items?.length === 0) {
      setHasItem(false)
      addProd()
    }
  }, [clientData]);

  const addProd = () => {
    if (selectedItem === 1) return;
    setItemList((list) => [...list, selectedItem]);
    setSelectedItem(clientData?.items[0] ?? "");
  };

  const removeProd = (id) => {
    let newList = [...itemList];
    newList = newList.filter((prod, index) => index !== id);
    setItemList(newList);
  };

  const changeItemText = (data) => {
    const text = data?.text;
    const indexItem = data?.indexItem;
    let newList = [...itemList];
    const item = newList[indexItem].split("-")[0];
    newList[indexItem] = `${item} - ${text}`;

    setItemList(newList);
  };

  if (!clientData) return;

  return (
    <Stack paddingY={2} spacing={2}>
        <Stack direction="row" gap={2}>
          <NullCheck valueForCheck={clientData?.items?.length > 0}>
            <Select
              sx={{ width: 200, paddingX: 1 }}
              size="small"
              value={selectedItem}
              onChange={(e) => setSelectedItem(e.target.value)}
            >
              {clientData?.items.map((item, index) => {
                return (
                  item?.isWebSale && (
                    <MenuItem sx={{ minWidth: 200 }} key={index} value={item.name}>
                      <Typography>{item.name}</Typography>
                    </MenuItem>
                  )
                );
              })}
            </Select>
          </NullCheck>

          <CustomButton 
            size="small" 
            title={hasItem ? "ADD_ITEM" : "ADD_FAULT"} 
            onClick={addProd} 
          />
        </Stack>

      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        {itemList?.length > 0 
        ? itemList?.map((item, index) => (
            <Fragment key={index}>
              <ListItem sx={{paddingX: 0}}>

              <Card sx={{width:"100%", border: '1px solid #000'}}>

                  <Stack spacing={1} pb={1} px={1}>
                    <Typography textAlign='start' variant="subtitle2" fontWeight="bold">
                      {item?.split("-")[0]}
                    </Typography>

                    <TextField
                        placeholder={t("DESC_PROBLEM")}
                        multiline
                        maxRows={4}
                        rows={5}
                        onChange={(e) =>
                          changeItemText({text: e.target.value, indexItem: index})
                        }
                    />
                  </Stack>
                  
                  <Stack 
                    padding={0.5} 
                    alignItems='end' 
                    borderTop='1px solid #000'
                    bgcolor="secondary.light"
                  >
                    <IconButton onClick={() => removeProd(index)}>
                      <Delete color="error" />
                    </IconButton>
                  </Stack>


                </Card>
              </ListItem>
              <Divider />
            </Fragment>
          ))
          : <Typography>{t(hasItem ? "ADD_ITEMS" : "ADD_FAULTS")}</Typography>
        }
      </List>
    </Stack>
  );
};
export default ClientItems;
